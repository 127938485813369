import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const useLocationFromIP = (url) => {
  const [userZipFromIP, setUserZipFromIP] = useState(null);
  const [userCountryFromIP, setUserCountryFromIP] = useState(() => {
    return localStorage.getItem('UCFIP') || null;
  });

  useEffect(() => {
    if (userCountryFromIP || Cookies.get('signerEmail')) return;

    const fetchData = async () => {
      try {
        const userCountry = await axios.get(url);
        const { country_code, zip } = userCountry?.data;
        setUserCountryFromIP(country_code);
        setUserZipFromIP(zip);
        localStorage.setItem('UCFIP', country_code);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [url, userCountryFromIP]);

  return { userCountryFromIP, userZipFromIP };
};

export default useLocationFromIP;
