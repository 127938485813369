import React, { createContext, useContext } from 'react';
import Cookies from 'js-cookie';
import { GlobalCtx } from './GlobalContext';
import useSinglePetition from '../hooks/useSinglePetition';
import useLastFiveSigners from '../hooks/useLastFiveSigners';
import useLocationFromIP from '../hooks/useLocationFromIP';
import useCountries from '../hooks/useCountries';
import { checkUrl } from '../components/Data';
import { useParams } from 'react-router-dom';

export const PetitionPageCtx = createContext();

const PetitionCtx = ({ children }) => {
  const { lang } = useContext(GlobalCtx);
  const { nid } = useParams();
  const nidDigits = nid?.match(/^\d+/)[0];
  const { petitionData, victoryPetition, petitionBody, featured_video_id } =
    useSinglePetition(
      `${checkUrl()}/api/v1/single_petition/?id=${nidDigits}`,
      lang,
      nidDigits
    );
    const shouldFetchLastSigners = !victoryPetition && !petitionData.recent_signatures_box;
  const { lastFiveSigners } = useLastFiveSigners(`${checkUrl()}/api/v1/last_five_signatures/`, {petition_id: nidDigits}, shouldFetchLastSigners);
  const { countries } = useCountries();
  const { signature_count, padding, cluster_count } = petitionData;
  const { userCountryFromIP, userZipFromIP } = useLocationFromIP(`${checkUrl()}/api/v1/get-client-ip`);
  const saveUserNameInCookie = (userFirstName, userEditingFirstName) => {
    Cookies.set('VWO_cp', generateEightPercent(), { expires: 30 });
    if (userFirstName === userEditingFirstName) {
      Cookies.set('VWO_ufn', userFirstName, { expires: 30 });
      return;
    } else {
      Cookies.set('VWO_ufn', userEditingFirstName, { expires: 30 });
    }
  };

  const generateSignatureCount = () => {
    if (signature_count + padding >= cluster_count + padding) {
      return (signature_count + padding).toLocaleString(
        lang === 'hazteoir' ? 'de-DE' : 'en-US'
      );
    } else if (cluster_count > signature_count) {
      return cluster_count.toLocaleString(
        lang === 'hazteoir' ? 'de-DE' : 'en-US'
      );
    } else {
      return (signature_count + padding).toLocaleString(
        lang === 'hazteoir' ? 'de-DE' : 'en-US'
      );
    }
  };

  const generateEightPercent = () => {
    const signatureCount = generateSignatureCount();
    const numericSignatureCount = Number(signatureCount.replace(/,/g, ''));
    return Math.round(numericSignatureCount * 0.08);
  };

  return (
    <PetitionPageCtx.Provider
      value={{
        saveUserNameInCookie,
        generateSignatureCount,
        petitionData,
        victoryPetition,
        petitionBody,
        featured_video_id,
        lastFiveSigners,
        countries,
        userCountryFromIP,
        userZipFromIP
      }}
    >
      {children}
    </PetitionPageCtx.Provider>
  );
};

export default PetitionCtx;
