import React, { createContext, useState, useEffect, useContext } from 'react';
import { GlobalCtx } from './GlobalContext';
import useHomePageData from '../hooks/useHomePage.data';
import { checkUrl, changeListCode } from '../components/Data';
import loadMore from '../hooks/loadMore';
import useVictoriesSlider from '../hooks/useVictoriesSlider';
import axios from 'axios';

export const HomePageCtx = createContext();

const HomeCtx = ({ children }) => {
  const [firstSix, setFirstSix] = useState([]);
  const [offset, setOffset] = useState(6);
  const [endOfList, setEndOfList] = useState(false);
  const [memberCounter, setMemberCounter] = useState(null);

  const { lang } = useContext(GlobalCtx);

  const { data, loading, error, setData } = useHomePageData(
    `${checkUrl()}/api/v1/petition/`,
    {
      limit: 6,
      segment: changeListCode(lang),
    }
  );

  const { victoriesSlider } = useVictoriesSlider(`${checkUrl()}/api/v1/petition/`, {
    limit: 6,
    status: [1, 3],
    segment: changeListCode(lang),
  });

  const memberCounterHandler = async () => {
    try {
      const res = await axios.get(`${checkUrl()}/api/v1/member-count`);
      setMemberCounter(Number(res.data.member_count).toLocaleString('en-US'));
    } catch(err) {
      console.log(err.message);
    }
  }

  useEffect(() => {
    if (data) {
      setFirstSix(data);
    }
  }, [data]);

  useEffect(() => {
    memberCounterHandler();
  }, []);

  const topics = [
    { path: "/topics/lfe", key: "topics.lf" },
    { path: "/topics/fam", key: "topics.fm" },
    { path: "/topics/fre", key: "topics.rf" },
    { path: "/topics/pat", key: "topics.pt" },
    { path: "/topics/ele", key: "topics.ec" },
    { path: "/topics/oth", key: "topics.ot" },
  ];

  const loadMoreHandler = () => loadMore(`${checkUrl()}/api/v1/petition/`, { segment: changeListCode(lang), limit: 5, offset: offset }, setData, setOffset, setEndOfList);

  return (
    <HomePageCtx.Provider
      value={{ firstSix, error, loading, offset, endOfList, loadMoreHandler, victoriesSlider, memberCounter, topics }}
    >
      {children}
    </HomePageCtx.Provider>
  );
};

export default HomeCtx;
