import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
const DelayedNavigate = ({ to, delay }) => {
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setRedirect(true), delay);
        return () => clearTimeout(timer);
    }, [delay]);

  return redirect ? <Navigate to={to} /> : null;
}

export default DelayedNavigate